export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export const SET_BUTTONS = "SET_BUTTONS";
export const SET_GENERAL_INFO = "SET_GENERAL_INFO";
export const SET_TEMP_DOCS = "SET_TEMP_DOCS";
export default {
  SET_UPLOAD_PROGRESS,
  SET_BUTTONS,
  SET_GENERAL_INFO,
  SET_TEMP_DOCS
};
