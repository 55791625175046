export default {
  state: {
    unassigned: [],
    unassignedSearched: [],
    assgnedToYou: [],
    assignedToYouSearched: [],
    unfinished: [],
    unfinishedSearched: [],
    recentlyFinished: [],
    recentlyFinishedSearched: [],

    unconfirmed: [],
    unconfirmedSearched: [],
    othersUnconfirmed: [],
    othersUnconfirmedSearched: [],

    returnedToMe: [],
    returnedToMeSearched: [],
    returnedToOthers: [],
    retunedToOthersSearched: [],

    confirmReview: [],
    confirmReviewSearched: [],
    othersConfirmReview: [],
    othersConfirmReviewSearched: [],

    evaluateReviewer: [],
    evaluateReviewerSearched: [],
    othresEvaluateReviewer: [],
    othresEvaluateReviewerSearched: [],

    confirmed: [],
    confirmedSearched: [],

    approved: [],
    approvedSearched: [],
    allApproved: [],
    allApprovedSearched: [],

    rejected: [],
    rejectedSearched: [],
    allRejected: [],
    allRejectedSearched: [],

    underSuperVision: [],
    underSuperVisionSearched: [],
    allUnderSuperVision: [],
    allUnderSuperVisionSearched: [],

    assignedForEveryOne: [],
    assignedForEveryOneSearched: [],

    everyOneUnfinished: [],
    eveyOneUnfinishedSearched: [],

    allRecentlyFinished: [],
    allRecentlyFinishedSearched: [],

    certifiedUsers: [],
    certifiedUsersSearched: [],

    allCertifiedUsers: [],
    allCertifiedUsersSearched: [],

    myRegionCertifiedUsers: [],
    myRegionCertifiedUsersSearched: [],

    pendingPayments: [],
    pendingPaymentsSearched: [],

    approvedPendingPayments: [],
    approvedPendingPaymentsSearched: [],

    allPendingPayment: [],
    allPendingPaymentSearched: [],

    allLicensed: [],
    allLicensedSearched: [],

    legacyData: [],
    legacyDataSearched: [],
    imported: []
  }
};
