import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import IdleVue from "idle-vue";
import "tw-elements";
import Toast from "vue-toastification";
import "./styles/main.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js"; 
import "boxicons/css/boxicons.min.css";
import { createI18n } from "vue-i18n";
const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  missingWarn: false,
  messages: {
    en: require("./locales/en.json"),
    am: require("./locales/am.json"),
  },
});
const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(IdleVue, {
  store,
  idleTime: 1800000,
  startAtIdle: false,
});

app.use(router);
app.use(Toast, { transition: "Vue-Toastification__slideBlurred" }); 
app.mount("#app");
