export const SET_UNASSIGNED = "SET_UNASSIGNED";
export const SET_UNASSIGNED_SEARCHED = "SET_UNASSIGNED_SEARCHED";
export const SET_ASSIGNED_TO_YOU = "SET_ASSIGNED_TO_YOU";
export const SET_ASSIGNED_TO_YOU_SEARCHED = "SET_ASSIGNED_TO_YOU_SEARCHED";
export const SET_UNFINISHED = "SET_UNFINISHED";
export const SET_UNFINISHED_SEARCHED = "SET_UNFINISHED_SEARCHED";

export const SET_UNCONFIRMED = "SET_UNCONFIRMED";
export const SET_UNCONFIRMED_SEARCHED = "SET_UNCONFIRMED_SEARCHED";
export const SET_OTHERS_UNCONFIRMED = "SET_OTHERS_UNCONFIRMED";
export const SET_OTHERS_UNCONFIRMED_SEARCHED =
  "SET_OTHERS_UNCONFIRMED_SEARCHED";

export const SET_RETURNED_TO_ME = "SET_RETURNED_TO_ME";
export const SET_RETURNED_TO_ME_SEARCHED = "SET_RETURNED_TO_ME_SEARCHED";
export const SET_RETURNED_TO_OTHERS = "SET_RETURNED_TO_OTHERS";
export const SET_RETURNED_TO_OTHERS_SEARCHED =
  "SET_RETURNED_TO_OTHERS_SEARCHED";

export const SET_CONFIRM_REVIEW = "SET_CONFIRM_REVIEW";
export const SET_CONFIRM_REVIEW_SEARCHED = "SET_CONFIRM_REVIEW_SEARCHED";
export const SET_OTHERS_CONFIRM_REVIEW = "SET_OTHERS_CONFIRM_REVIEW";
export const SET_OTHERS_CONFIRM_REVIEW_SEARCHED =
  "SET_OTHERS_CONFIRM_REVIEW_SEARCHED";

export const SET_EVALUATE_REVIEW = "SET_EVALUATE_REVIEW";
export const SET_EVALUATE_REVIEW_SEARCHED = "SET_EVALUATE_REVIEW_SEARCHED";
export const SET_OTHERS_EVALAUTE_REVIEW = "SET_OTHERS_EVALAUTE_REVIEW";
export const SET_OTHERS_EVALUATE_REVIEW_SEARCHED =
  "SET_OTHERS_EVALUATE_REVIEW_SEARCHED";

export const SET_CONFIRMED = "SET_CONFIRMED";
export const SET_CONFIRMED_SEARCHED = "SET_CONFIRMED_SEARCHED";

export const SET_TEMPORARLY_FINISHED = "SET_TEMPORARLY_FINISHED";
export const SET_TEMPORARLY_FINISHED_SEARCHED =
  "SET_TEMPORARLY_FINISHED_SEARCHED";

export const SET_RECENTLY_FINISHED = "SET_RECENTLY_FINISHED";
export const SET_RECENTLY_FINISHED_SEARCHED = "SET_RECENTLY_FINISHED_SEARCHED";
export const SET_ASSIGNED_FOR_EVERYONE = "SET_ASSIGNED_FOR_EVERYONE";
export const SET_ASSIGNED_FOR_EVERYONE_SEARCHED =
  "SET_ASSIGNED_FOR_EVERYONE_SEARCHED";
export const SET_EVEYONE_UNFINISHED = "SET_EVEYONE_UNFINISHED";
export const SET_EVEYONE_UNFINISHED_SEARCHED =
  "SET_EVEYONE_UNFINISHED_SEARCHED";
export const SET_ALL_RECENTLY_FINISHED_SEARCHED =
  "SET_ALL_RECENTLY_FINISHED_SEARCHED";
export const SET_ALL_PENDING_PAYMENTS = "SET_ALL_PENDING_PAYMENTS";
export const SET_ALL_PENDING_PAYMENTS_SEARCHED =
  "SET_ALL_PENDING_PAYMENTS_SEARCHED";
export const SET_PENDING_PAYMENTS = "SET_PENDING_PAYMENTS";
export const SET_PENDING_PAYMENTS_SEARCHED = "SET_PENDING_PAYMENTS_SEARCHED";
export const SET_ALLRECENTLY_FINISHED = "SET_ALLRECENTLY_FINISHED";

export const SET_ALL_FINISHED_SEARCHED = "SET_ALL_FINISHED_SEARCHED";

export const SET_APPROVED_PENDING_PAYMENTS = "SET_APPROVED_PENDING_PAYMENTS";
export const SET_APPROVED_PENDING_PAYMENTS_SEARCHED =
  "SET_APPROVED_PENDING_PAYMENTS_SEARCHED";

export const SET_CERTIFIED_USERS = "SET_CERTIFIED_USERS";
export const SET_CERTIFIED_USERS_SEARCHED = "SET_CERTIFIED_USERS_SEARCHED";

export const SET_MY_REGION_CERTIFIED_USERS = "SET_MY_REGION_CERTIFIED_USERS";
export const SET_MY_REGION_CERTIFIED_USERS_SEARCHED =
  "SET_MY_REGION_CERTIFIED_USERS_SEARCHED";

export const SET_ALL_LICENSED = "SET_ALL_LICENSED";
export const SET_ALL_LICENSED_SEARCHED = "SET_ALL_LICENSED_SEARCHED";

export const SET_LEGACY_DATA = "SET_LEGACY_DATA";
export const SET_LEGACY_DATA_SEARCHED = "SET_LEGACY_DATA_SEARCHED";

export const SET_IMPORTED = "SET_IMPORTED";

export default {
  SET_UNASSIGNED,
  SET_UNASSIGNED_SEARCHED,
  SET_ASSIGNED_TO_YOU,
  SET_ASSIGNED_TO_YOU_SEARCHED,
  SET_UNFINISHED,
  SET_UNFINISHED_SEARCHED,

  SET_UNCONFIRMED,
 
  SET_OTHERS_UNCONFIRMED,
  SET_OTHERS_UNCONFIRMED_SEARCHED,

  SET_RETURNED_TO_ME,
  SET_RETURNED_TO_ME_SEARCHED,
  SET_RETURNED_TO_OTHERS,
  SET_RETURNED_TO_OTHERS_SEARCHED,

  SET_CONFIRM_REVIEW,
  SET_CONFIRM_REVIEW_SEARCHED,
  SET_OTHERS_CONFIRM_REVIEW,
  SET_OTHERS_CONFIRM_REVIEW_SEARCHED,

  SET_EVALUATE_REVIEW,
  SET_EVALUATE_REVIEW_SEARCHED,
  SET_OTHERS_EVALAUTE_REVIEW,
  SET_OTHERS_EVALUATE_REVIEW_SEARCHED,

  SET_CONFIRMED,
  SET_CONFIRMED_SEARCHED,

  SET_TEMPORARLY_FINISHED,
  SET_TEMPORARLY_FINISHED_SEARCHED,

  SET_RECENTLY_FINISHED_SEARCHED,
  SET_ASSIGNED_FOR_EVERYONE,
  SET_ASSIGNED_FOR_EVERYONE_SEARCHED,
  SET_EVEYONE_UNFINISHED,
  SET_EVEYONE_UNFINISHED_SEARCHED,
  SET_ALL_RECENTLY_FINISHED_SEARCHED,
  SET_ALL_PENDING_PAYMENTS,
  SET_ALL_PENDING_PAYMENTS_SEARCHED,
  SET_PENDING_PAYMENTS,
  SET_PENDING_PAYMENTS_SEARCHED,

  SET_APPROVED_PENDING_PAYMENTS,
  SET_APPROVED_PENDING_PAYMENTS_SEARCHED,

  SET_ALLRECENTLY_FINISHED,
  SET_ALL_FINISHED_SEARCHED,

  SET_CERTIFIED_USERS,
  SET_CERTIFIED_USERS_SEARCHED,

  SET_MY_REGION_CERTIFIED_USERS,
  SET_MY_REGION_CERTIFIED_USERS_SEARCHED,

  SET_LEGACY_DATA,
  SET_LEGACY_DATA_SEARCHED,
  SET_IMPORTED
};
