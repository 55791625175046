export const SET_GOOD_STANDING_UNASSIGNED = "SET_GOOD_STANDING_UNASSIGNED";
export const SET_GOOD_STANDING_UNASSIGNED_SEARCHED =
  "SET_GOOD_STANDING_UNASSIGNED_SEARCHED";

export const SET_GOOD_STANDING_UNFINISHED = "SET_GOOD_STANDING_UNFINISHED";
export const SET_GOOD_STANDING_UNFINISHED_SEARCHED =
  "SET_GOOD_STANDING_UNFINISHED_SEARCHED";
export const SET_GOOD_STANDING_OTHERS_UNFINISHED =
  "SET_GOOD_STANDING_OTHERS_UNFINISHED";
export const SET_GOOD_STANDING_OTHERS_UNFINISHED_SEARCHED =
  "SET_GOOD_STANDING_OTHERS_UNFINISHED_SEARCHED";

export const SET_GOOD_STANDING_ASSIGNED_TO_YOU =
  "SET_GOOD_STANDING_ASSIGNED_TO_YOU";
export const SET_GOOD_STANDING_ASSIGNED_TO_YOU_SEARCHED =
  "SET_GOOD_STANDING_ASSIGNED_TO_YOU_SEARCHED";
export const SET_GOOD_STANDING_ASSIGNED_TO_OTHERS =
  "SET_GOOD_STANDING_ASSIGNED_TO_OTHERS";
export const SET_GOOD_STANDING_ASSIGNED_TO_OTHERS_SEARCHED =
  "SET_GOOD_STANDING_ASSIGNED_TO_OTHERS_SEARCHED";

export const SET_GOOD_STANDING_APPROVED = "SET_GOOD_STANDING_APPROVED";
export const SET_GOOD_STANDING_APPROVED_SEARCHED =
  "SET_GOOD_STANDING_APPROVED_SEARCHED";
export const SET_GOOD_STANDING_ALL_APPROVED = "SET_GOOD_STANDING_ALL_APPROVED";
export const SET_GOOD_STANDING_ALL_APPROVED_SEARCHED =
  "SET_GOOD_STANDING_ALL_APPROVED_SEARCHED";

export const SET_GOOD_STANDING_DECLINED = "SET_GOOD_STANDING_DECLINED";
export const SET_GOOD_STANDING_DECLINED_SEARCHED = "SET_GOOD_STANDING_DECLINED_SEARCHED";
export const SET_GOOD_STANDING_ALL_DECLINED = "SET_GOOD_STANDING_ALL_DECLINED";
export const SET_GOOD_STANDING_ALL_DECLINED_SEARCHED =
  "SET_GOOD_STANDING_ALL_DECLINED_SEARCHED";

export const  SET_GOOD_STANDING_PENDING_PAYMENT =
" SET_GOOD_STANDING_PENDING_PAYMENT";
export const  SET_GOOD_STANDING_PENDING_PAYMENT_SEARCHED = 
" SET_GOOD_STANDING_PENDING_PAYMENT_SEARCHED";
export const  SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT = 
" SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT";
export const  SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT_SEARCHED =
" SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT_SEARCHED";

export const  SET_GOOD_STANDING_DECLINED_PAYMENT =
" SET_GOOD_STANDING_DECLINED_PAYMENT";
export const  SET_GOOD_STANDING_DECLINED_PAYMENT_SEARCHED =
" SET_GOOD_STANDING_DECLINED_PAYMENT_SEARCHED";
export const  SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT =
" SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT";
export const  SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT_SEARCHED =
" SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT_SEARCHED";

export const  SET_GOOD_STANDING_LICENSED =
" SET_GOOD_STANDING_LICENSED";
export const  SET_GOOD_STANDING_LICENSED_SEARCHED = 
" SET_GOOD_STANDING_LICENSED_SEARCHED";
export const  SET_GOOD_STANDING_OTHERS_LICENSED = 
" SET_GOOD_STANDING_OTHERS_LICENSED";
export const  SET_GOOD_STANDING_OTHERS_LICENSED_SEARCHED =
" SET_GOOD_STANDING_OTHERS_LICENSED_SEARCHED";

export const SET_GOOD_STANDING_ALL_LICENSED =
  "SET_GOOD_STANDING_ALL_LICENSED";
export const SET_GOOD_STANDING_ALL_LICENSED_SEARCHED = 
  "SET_GOOD_STANDING_ALL_LICENSED_SEARCHED"; 

export const SET_GOOD_STANDING_RE_APPLY =
  "SET_GOOD_STANDING_RE_APPLY";
export const SET_GOOD_STANDING_RE_APPLY_SEARCHED = 
  "SET_GOOD_STANDING_RE_APPLY_SEARCHED";
export const SET_GOOD_STANDING_OTHERS_RE_APPLY = 
  "SET_GOOD_STANDING_OTHERS_RE_APPLY";
export const SET_GOOD_STANDING_OTHERS_RE_APPLY_SEARCHED =
  "SET_GOOD_STANDING_OTHERS_RE_APPLY_SEARCHED";


export default {
  SET_GOOD_STANDING_UNASSIGNED,
  SET_GOOD_STANDING_UNASSIGNED_SEARCHED,

  SET_GOOD_STANDING_UNFINISHED,
  SET_GOOD_STANDING_UNFINISHED_SEARCHED,
  SET_GOOD_STANDING_OTHERS_UNFINISHED,
  SET_GOOD_STANDING_OTHERS_UNFINISHED_SEARCHED,

  SET_GOOD_STANDING_ASSIGNED_TO_YOU,
  SET_GOOD_STANDING_ASSIGNED_TO_YOU_SEARCHED,
  SET_GOOD_STANDING_ASSIGNED_TO_OTHERS,
  SET_GOOD_STANDING_ASSIGNED_TO_OTHERS_SEARCHED,

  SET_GOOD_STANDING_APPROVED,
  SET_GOOD_STANDING_APPROVED_SEARCHED,
  SET_GOOD_STANDING_ALL_APPROVED,
  SET_GOOD_STANDING_ALL_APPROVED_SEARCHED,

  SET_GOOD_STANDING_DECLINED,
  SET_GOOD_STANDING_DECLINED_SEARCHED,
  SET_GOOD_STANDING_ALL_DECLINED,
  SET_GOOD_STANDING_ALL_DECLINED_SEARCHED,

  SET_GOOD_STANDING_PENDING_PAYMENT,
  SET_GOOD_STANDING_PENDING_PAYMENT_SEARCHED,
  SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT,
  SET_GOOD_STANDING_OTHERS_PENDING_PAYMENT_SEARCHED,

  SET_GOOD_STANDING_DECLINED_PAYMENT,
  SET_GOOD_STANDING_DECLINED_PAYMENT_SEARCHED,
  SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT,
  SET_GOOD_STANDING_OTHERS_DECLINED_PAYMENT_SEARCHED,

  SET_GOOD_STANDING_LICENSED,
  SET_GOOD_STANDING_LICENSED_SEARCHED,
  SET_GOOD_STANDING_OTHERS_LICENSED,
  SET_GOOD_STANDING_OTHERS_LICENSED_SEARCHED,

  SET_GOOD_STANDING_ALL_LICENSED,
  SET_GOOD_STANDING_ALL_LICENSED_SEARCHED,

  SET_GOOD_STANDING_RE_APPLY,
  SET_GOOD_STANDING_RE_APPLY_SEARCHED,
  SET_GOOD_STANDING_OTHERS_RE_APPLY,
  SET_GOOD_STANDING_OTHERS_RE_APPLY_SEARCHED,
};
