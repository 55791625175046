<template>
  <!-- w-full blue-gradient flex justify-center items-center -->
  <div
    :class="isDarkMode?'w-full bg-secondaryDark text-white flex justify-center items-center py-large sm:py-xl box-border':'w-full bg-white text-main-400 flex justify-center items-center py-large sm:py-xl box-border'"
    
  >
    <section
      class="content-wrapper flex flex-col md:flex-row justify-center md:justify-start items-center"
    >
 
      <transition name="slide-fade-to-top">
        <section
          class="flex flex-col px-small sm:ml-large items-center md:items-start"
         
        >
          <div class="flex flex-col items-center">
    
          </div>
          <p class="mt-small text-2xl text-center">
            {{
              $t(
                "If your application is approved, you are expected to pay a service fee and take your license. Please note that foreigners will pay in dollars and Ethiopians will pay in birr."
              )
            }}
          </p>
        </section>
      </transition>
    </section>
  </div>
</template>
<script> 
export default {
  props: ["isDarkMode"],
  setup() { 
    return { 
    };
  },
};
</script>
<style lang="postcss" scoped>
.new-license-wrapper {
  height: 370px;
}

.content-wrapper {
  width: 90%;
  max-width: 714px;
}

svg {
  height: 143px !important;
}
</style>
